.container {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  gap: 20px;
  background-color: rgb(44, 44, 44);
  padding: 20px;
  border-radius: 20px;
  height: calc(90vh - 40px);
  overflow-y: auto;
}
.oldInfo,
.newInfo {
  width: 100%;
  display: flex;
  gap: 20px;
}
.columnBlock {
  width: 50%;
}
.avatar {
  width: 300px;
  height: 300px;
  border-radius: 20px;
}
.diffElement {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.newValue {
  color: rgb(178, 252, 171);
}
.oldValue {
  color: rgb(253, 187, 187);
}
.video {
  max-height: 400px;
}
.oldValueTextBlock,
.newValueTextBlock {
  padding: 20px;
  border-radius: 20px;
  color: white;
  margin-bottom: 10px;
}
.oldValueTextBlock {
  background-color: rgb(185, 110, 110);
  white-space: pre-wrap;
}
.newValueTextBlock {
  background-color: rgb(106, 148, 102);
  white-space: pre-wrap;
}
