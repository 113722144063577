.container {
  width: calc(100%);
  background-color: transparent;
  height: 100%;
  border-radius: 20px;
  color: white;
}
.editContainer {
  width: 100%;
  background-color: transparent;
  height: 100%;
  border-radius: 20px;
}
.profileInfoBlock {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
}
.headBlock {
  margin-bottom: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 25%;
  height: fit-content;
}
.warningMessage {
  background-color: rgba(229, 90, 90, 0.2);
  color: var(--main-red);
  border-radius: 20px;
  padding: 8px 20px;
  width: fit-content;
  margin-bottom: 10px;
}
.headRatingBlock {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.basedOnBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}
.basedOnBlock span:nth-child(1) {
  color: #727272;
}
.basedOnBlock span:nth-child(2) {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 700;
}
.headRatingValueBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #131313;
  padding: 15px 10px;
  border-radius: 20px;
  flex: 1;
}
.headRatingValueBlock div {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
}
.totalCertificateBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #131313;
  color: black;
  padding: 15px 10px;
  border-radius: 20px;
  width: 33%;
  color: white;
}
.headRatingValueBlock div,
.totalCertificateBlock div {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
}
.numberInfoValue {
  font-size: 20px;
}
.totalCertificatesLable {
  max-width: 200px;
  text-wrap: wrap;
}
.subSpecializationList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 10px;
}
.subSpecializationBlock {
  margin-bottom: 20px;
  margin-top: 20px;
}
.subSpecElement {
  display: flex;
  align-items: center;
  gap: 5px;
}
.subSpecIndicator {
  width: 6px;
  height: 6px;
  background-color: var(--primary-color);
  border-radius: 50%;
}
.subSpecTitle {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 16px;
}
.expInfoBlock {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
}
.headBlockMainContent {
  display: flex;
  flex-direction: column;
  background-color: #131313;
  padding: 20px;
  border-radius: 20px;
}
.dynamicHeadBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabsBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}
.profileSelectLangBlock {
  min-width: 200px;
}
.activeTab {
  position: relative;
  cursor: pointer;
  color: var(--primary-color);
  font-weight: 600;
}
.notActiveTab {
  cursor: pointer;
  font-weight: 600;
  color: #909090;
}
.tabActiveIndicator {
  position: absolute;
  bottom: -10px;
  height: 3px;
  background-color: var(--primary-color);
  width: 100%;
  left: 0;
  border-radius: 20px;
}
.guestProfileActionBlock,
.myProfileActionBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.headPriceBlock {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.priceWithDiscountBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.currentPrice {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 20px;
  font-size: 22px;
  font-weight: 600;
}
.specToolsBtn {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #075736;
  cursor: pointer;
  border: none;
  background-color: var(--secondary-color);
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.specToolsBtn:hover {
  filter: brightness(95%);
}
.briefDescription {
  word-break: break-all;
}
.dynamiContentBlock {
  width: calc(75% - 40px);
  background-color: #000000;
  border-radius: 20px;
  padding: 20px;
}
.packagesBlock {
  margin-top: 20px;
}
.packagesListBlock {
  display: flex;
  flex-direction: column;
}
.mainInfoBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.headNameBlock {
  margin-bottom: 10px;
}
.nameBlock {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.nameTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.ageTitle {
  color: #909090;
  margin-bottom: 15px;
}
.verifyIcon {
  margin-left: 10px;
}
.rightMenuInfoBlock {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary-color);
  margin-bottom: 5px;
}
.totalInfoBlock {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 10px;
}
.actionBlock {
  margin-bottom: 20px;
}
.infoIcon path {
  fill: gray;
}
.editProfileButton {
  border-radius: 20px;
  height: 40px;
  border: none;
  color: white;
  display: flex;
  justify-content: space-between;
  background-color: var(--primary-color);
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  transition: 0.3s;
}
.editIcon {
  margin-right: 1rem;
}
.editIcon path {
  transition: 0.3s;
  fill: white;
}
.editProfileButton:hover {
  transform: scale(1.05);
}
.leftProfileBlock {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
}
.rightProfileBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.aboutVideoBlock {
  display: flex;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}
.generalInfoBlock {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 20px;
  height: 140px;
}
.generalInfoElement {
  width: 25%;
  display: flex;
  align-items: center;
}
.generalTitleBlock {
  display: flex;
  flex-direction: column;
}
.generalTitleBlock span:nth-child(1) {
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #075736;
}
.generalTitleBlock span:nth-child(2) {
  color: #696969;
  font-size: 14px;
  font-weight: 600;
}
.genderalIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.blockLable {
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  color: #717171;
}
.aboutTitleBlock {
  padding-right: 10px;
}
.aboutTitle {
  margin: 10px 0 0 0;
  font-size: 16px;
  height: fit-content;
  line-height: 22px;
}
.showMoreBtn {
  border-radius: 20px;
  width: fit-content;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
}
.showMoreBtn p {
  margin: 0;
  color: #696969;
}
.chevronRotate {
  transform: rotate(180deg);
}
.reviewsBlock {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
}
.reviewsBlock h4 {
  margin-top: 0;
}
.commentCard {
  background-color: #131313;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
  height: 175px;
}
.commentsListBlock {
  max-height: 350px;
  overflow-y: auto;
  padding-right: 20px;
}
.ratingBlock {
  display: flex;
  align-items: center;
}
.ratingBlock span {
  font-size: 18px;
  color: var(--primary-color);
  margin-top: 3px;
  font-weight: 600;
  margin-left: 7px;
}
.videoInProcessBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--gray-text-color);
  font-size: 20px;
  font-weight: 600;
  height: 300px;
  width: 168px;
  border-radius: 20px;
  border: 1px solid var(--border);
}
.previewVideoBlock {
  width: fit-content;
  height: 300px;
  position: relative;
  border-radius: 20px;
  /* background-color: white; */
  min-width: 168px;
}
.previewVideoImg {
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
  width: 170px;
}
.videoPreviewSkeleton {
  position: absolute;
  top: 0;
  z-index: 9999;
}
.videoSkeleton {
  background-color: rgb(202, 202, 202);
  border-radius: 20px;
}
.playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 9999;
}
.video {
  max-height: 90svh;
}
.educationBlock {
  padding: 20px;
  border-radius: 20px;
  background-color: white;

  margin-top: 20px;
}
.educationBlock h4 {
  margin-top: 0;
}
.educationElement {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
}
.educationIconBlock {
  margin-right: 15px;
}

.fileIcon path {
  fill: var(--primary-color);
}
.certificateTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
  word-break: break-all;
}
.certificateTitleBlock {
  display: flex;
  flex-direction: column;
}
.integrationsBlock {
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  margin-top: 20px;
}
.integrationsBlock h4 {
  margin-top: 0;
}
.mainSpecTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}
.workWithSection {
  margin-bottom: 20px;
}
.workWithList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.workWithElement {
  border-radius: 20px;
  padding: 6px 20px;
  background-color: #131313;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
}
.pagginationBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.eyeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.eyeIcon:hover path {
  stroke: var(--primary-color);
}
.previewImg {
  max-width: 50svw;
  max-height: 70svh;
}
.leftEducationElContent {
  display: flex;
  align-items: center;
  max-width: 80%;
}
.educationLoaderBlock {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 20px 0;
}
.imagePreviewBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pdfWorker {
  height: 70svh;
  width: 50svw;
  margin-left: auto;
  margin-right: auto;
}
.mobileVideoEducationBlock {
  display: none;
}
.commentText {
  max-width: fit-content;
  text-wrap: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
  margin: 0;
}
.commentTextBlock {
  overflow: auto;
  height: calc(100% - 20px);
}
.myProfileActionBtn {
  display: flex;
  align-items: center;
  gap: 10px;
}
.oldPrice {
  text-decoration: line-through;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0;
  }
  .mobileVideoEducationBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
  }
  .headNameBlock {
    flex-direction: column;
    justify-content: center;
  }
  .nameBlock {
    align-items: center;
  }
  .headBlock {
    flex-direction: column;
  }
  .headBlockMainContent {
    flex-direction: column;
    align-items: center;
  }
  .commentsListBlock {
    padding: 0;
  }
  .previewVideoBlock {
    padding: 0;
    width: 100%;
  }
  .educationBlock {
    padding: 0;
  }
  .activeTab,
  .notActiveTab {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .actionBlock {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .mainInfoBlock {
    flex-direction: column;
  }
  .leftProfileBlock {
    width: 100%;
  }
  .rightProfileBlock {
    display: none;
  }
  .generalInfoBlock {
    height: 280px;
    width: calc(100%);
    padding: 0;
    border: none;
  }
  .generalInfoElement {
    width: 50%;
  }
  .reviewsBlock {
    margin-bottom: 20px;
    padding: 0;
    border: none;
  }
  .video {
    max-width: 90svw;
  }
  .pdfWorker {
    width: 90svw;
  }
  .previewImg {
    max-width: 90svw;
  }
}
