.container {
  width: 450px;
  max-height: 85vh;
  overflow-y: auto;
  color: white;
}
.loaderContainer {
  width: 450px;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dateBlock {
  margin-top: 20px;
  margin-bottom: 20px;
}
.inputBlock {
  margin-top: 20px;
}
.dateInputBlock {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.dateInput {
  width: 50%;
}
.addCertificateBtn {
  border: 1px dashed #dadada;
  padding: 0 20px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 10px;
  cursor: pointer;
  color: #909090;
}
.addCertificateBtn span {
  margin-bottom: 3px;
  font-weight: 600;
}
.addCertificateBtn:hover {
  color: var(--primary-color);
  border: 1px dashed var(--primary-color);
}
.addCertificateBtn:hover .plusIcon path {
  stroke: var(--primary-color);
}
.updateAvatarBtn {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  cursor: pointer;
}
.updateAvatarBtn img {
  margin-right: 15px;
}
.updateAvatarBtn span {
  font-size: 18px;
  font-weight: 600;
}
.headLable {
  font-size: 20px;
  font-weight: 600;
  display: block;
}
.secondLable {
  display: block;
  color: #909090;
  margin-bottom: 10px;
}
.newFederationBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.badgeIcon {
  width: 40px;
  height: 40px;
}
.uploadFilesActionBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.uploadCertificateBlock {
  margin-bottom: 20px;
}
.selectFinishedStudyBlock {
  display: inline-block;
}
.closeIcon path {
  /*   stroke: red; */
  fill: red;
}
@media screen and (max-width: 768px) {
  .container {
    width: calc(100vw - 60px);
  }
}
