:root {
  --text-color: white; /* Основной цвет текста */
}

.priceBlock {
  border-radius: 20px;
  background-color: black;
  width: calc(100% - 0px);
  margin: 20px 0;
}

.priceDescription {
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--text-color);
  text-align: center;
  font-weight: 600;
}

.priceMultiple {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}

.priceItem {
  flex: 0 0 auto;
  padding: 20px;
  border: 1px solid #303030;
  border-radius: 20px;
  background-color: #131313;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1;
}
.wrapPriceitem {
  flex: 0 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #131313;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: calc(25% - 57px);
}
.priceItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.language {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 10px;
}

.price {
  font-size: 22px;
  font-weight: bold;
  color: var(--primary-color); /* Выделяем новую цену */
  margin-bottom: 5px;
}

.oldPrice {
  font-size: 16px;
  color: #ff4d4d; /* Старая цена */
  text-decoration: line-through;
}

.savings {
  font-size: 14px;
  font-weight: bold;
  color: black; /* Выгода клиента */
}

.priceNote {
  font-size: 12px;
  color: #555;
  margin-top: 10px;
}
