.container {
  display: flex;
  flex-direction: column;
  color: white;
}
.certificateTypeTitle {
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 0;
  font-weight: 600;
  display: block;
}

.loaderContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.badgeIcon {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
.thumbnailCertificate {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.emtyCertificateImg {
  width: 100%;
  max-height: 200px;
  border-radius: 10px;
}
.emptyCertificateBlock {
  width: 100%;
  max-height: 250px;
  border-radius: 10px;
  position: relative;
}
.blurBlock {
  width: 100%;
  height: 200px;
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  border-radius: 10px;
}
.certificateElement {
  background-color: #131313;
  border-radius: 20px;
  /*   padding: 20px; */
  width: 100%;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.2));
  gap: 20px;
  display: block;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 20px;
  break-inside: avoid;
}
.mainInfoCertificateBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 40px);
  padding: 10px 20px 10px 20px;
  color: white;
}
.certificateListBlock {
  -moz-column-count: 3;
  -webkit-column-count: 3;
  column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  width: 100%;
}
.educationBlock {
  padding: 20px;
}

.headCertificateBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.headCertificateBlock span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  word-break: break-all;
}
.bottomCertificateBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}
.imagePreviewBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.pdfWorker {
  height: 70svh;
  width: 50svw;
  margin-left: auto;
  margin-right: auto;
}
.previewImg {
  max-width: 50svw;
  max-height: 70svh;
  border-radius: 20px;
}
.showCertificateBtn {
  cursor: pointer;
  width: 100%;
  background-color: #131313;
}

.educationDescription {
  color: #727272;
  font-weight: 600;
  display: block;
}
.subspecializationsBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.subspecializationsBlock span {
  background-color: rgb(59, 59, 59);
  border-radius: 20px;
  padding: 4px 8px;
  font-size: 14px;
}
.actionBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
}
.editBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  height: 42px;
  width: 42px;
  background-color: var(--primary-color);
  cursor: pointer;
  transition: 0.3s;
}
.editBtn:hover {
  background-color: var(--primary-hover);
  transition: 0.3s;
}
.editBtn path {
  stroke: white;
}
@media screen and (max-width: 768px) {
  .previewImg {
    max-width: 90svw;
  }
  .activeTab,
  .notActiveTab {
    font-size: 12px;
    text-align: center;
  }
  .certificateElement {
    width: 100%;
  }
  .certificateListBlock {
    max-height: 200px;
    overflow-y: auto;
  }
  .pdfWorker {
    width: 95vw;
  }
}
