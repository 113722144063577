.settingsBlock {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  margin-top: 20px;
  height: calc(100vh - 380px);
}
.profileVerifyStatusTitleBlock {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}
.openProfileBtnsBlock {
  display: flex;
  align-items: center;
  gap: 5px;
}
.viewUpdatedProfileBtn {
  color: var(--primary-color);
  text-decoration: underline;
  cursor: pointer;
}
.verifiedColumn {
  color: #3fb082;
  background-color: rgba(63, 176, 131, 0.25);
  padding: 5px 8px;
  border-radius: 10px;
  text-transform: capitalize;
}
.unverifiedColumn {
  color: #e34a4a;
  background-color: rgba(227, 74, 74, 0.25);
  padding: 5px 8px;
  border-radius: 10px;
}

.statusBlockTitle {
  display: flex;
  align-items: center;
}
.statusBlockTitle span:nth-child(1) {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 1px;
}
.statusBlockTitle span:nth-child(2) {
  color: white;
}
.selectStatusBlock {
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  background-color: #313131;
  border-radius: 5px;
  padding: 10px 20px;
}
.cuurentVerifyStatusBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.animHeightStatus div {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}
.animHeightStatus div span {
  width: 150px;
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selectStatusElement {
  width: 100%;
  cursor: pointer;
}
.selectStatusElement:hover {
  background-color: #303a36;
}
