.container {
  max-width: 80vw;
  height: calc(80vh + 55px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tollsBlock {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  color: white;
}
