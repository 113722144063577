.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100svh;
  padding: 20px;
  overflow: auto;
  color: white;
  gap: 40px;
}
.statsLabel {
  margin: 0 0 20px 0;
}
.mainLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statsListBlock {
  display: flex;
  align-items: center;
  width: 100%;
  height: fit-content;
  gap: 10px;
}
.statisticCardBlock {
  width: 20%;
  height: 120px;
}
