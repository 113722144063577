.sidebarContainer {
  display: flex;
  min-height: 100vh;
  position: relative;
}
.sidebarContainer aside {
  border: none !important;
}
.sidebarContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.sidebarHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sidebarHeader img {
  height: 150px;
  width: 150px;
}
.sidebarHeader h3 {
  margin-top: 0;
}
.menuIcon,
.selectedMenuIcon {
  width: 20px;
  height: 20px;
}
.selectedMenuIcon {
  fill: white;
}
.menuIcon path {
  transition: 0.3s;
}
.block {
  display: flex;
  align-items: center;
}
.subMenuIconSelect path {
  stroke: white;
}
.subMenuIcon path {
  /*  fill: black; */
}
.menuItemSelect .menuIcon path,
.menuItemSelect .menuIcon circle {
  /* fill: white; */
  stroke: white;
}
.menuItemSelect .fillMenuIcon path {
  fill: white;
}
.menuItem:hover .menuIcon path {
  /*   fill: var(--primary-color); */
  stroke: white;
}
.menuItem:hover .fillMenuIcon path {
  fill: white;
}
.menuItem:hover .menuIcon circle {
  /*   fill: var(--primary-color); */
  stroke: white;
}
.menuItem:hover .subMenuIcon path {
  /* fill: var(--primary-color); */
  stroke: white;
}
.generalBlock {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 5px;
}
.generalBlock p {
  opacity: 0.6;
  margin: 0;
}
.bottomButtonsBlock {
  margin-left: 3rem;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.bottomButtonsBlockCollapsed {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoutButton,
.settingsButton {
  outline: none;
  border: none;
  display: flex;
  /*   justify-content: space-between; */
  align-items: center;
  background-color: transparent;
  width: 50%;
  align-self: center;
  margin-bottom: 2rem;
  cursor: pointer;
  color: white;
}
.logoutButtonIcon {
  margin-right: 10px;
}
.logoutButtonIcon path,
.settingsButtonIcon path {
  transition: 0.5s;
  stroke: #696969;
}
.settingsButton:hover .settingsButtonIcon path {
  fill: var(--primary-color);
}
.logoutButton:hover .logoutButtonIcon path {
  stroke: red;
}
.logoutButton p,
.settingsButton p {
  margin: 0;
}
.menuLeftBlock,
.collapsedMenuLeftBlock {
  width: 10px;
  height: 40px;
  background-color: var(--primary-color);
  margin-right: 1rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.collapsedMenuLeftBlock {
  margin-right: 0.5rem;
}
.menuItem,
.menuItemSelect {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuItemSelect a {
  opacity: 1 !important;
  /*   background-color: var(--primary-color) !important; */
}
.menuItem {
  border-radius: 20px;
}
.logoBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.logoBlock img {
  height: 100px;
  width: 100px;
  margin-top: 3rem;
}

.menuItemSelect a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.menuItemSelect a span,
.menuItem a span {
  margin-right: 0 !important;
}
.submenuItem {
  font-size: 12px !important;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.subMenuItemSelected {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: black !important;
}
.subMenu ul {
  background-color: white;
  width: 100%;
  z-index: 10;
  position: relative;
}

@media screen and (max-width: 1300px) {
  .sideBar {
    min-width: 180px !important;
    width: 180px !important;
  }
  .sideBarClose {
    min-width: 80px !important;
    width: 80px !important;
  }
  .bottomButtonsBlock {
    display: flex;
    flex-direction: column;
    margin-left: 0;
  }
  .menuLink span {
    font-size: 12px !important;
    /*     height: 40px; */
  }
  .menuIcon,
  .subMenuIcon,
  .subMenuIconSelect,
  .settingsButtonIcon,
  .logoutButtonIcon {
    width: 15px;
    height: 15px;
  }
  .submenuItem a {
    padding-left: 5px !important;
  }
}
