.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  margin-top: 20px;
  /*   height: calc(100% - 220px); */
  flex: 1;
  color: white;
  padding-bottom: 20px;
}
.description {
  margin: 0;
}
.serviceAllowedBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statusBlockTitle {
  display: flex;
  align-items: center;
}
.statusBlockTitle > span:nth-child(1) {
  display: inline-block;
  width: 7px;
  height: 7px;
  background-color: var(--primary-color);
  border-radius: 50%;
  margin-right: 5px;
  margin-top: 1px;
}
.statusBlockTitle span:nth-child(2) {
  color: white;
}
.configTitleBlock {
  display: flex;
  flex-direction: column;
}
.configTitleBlock span:nth-child(2) {
  color: var(--primary-color);
}
.reviewedBlock {
  display: flex;
  justify-content: flex-end;
}
